body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Inter', sans-serif, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 
  Global style overrides
  TODO: Find a way to handle these within mui theme definition
*/

body {
  background: #fff;
}
